<div class="col-12 px-lg-4" *ngIf="(hasSuggestions || hasSuggestionsAI) && !isLoadingSuggestionAI">

  <div class="d-flex justify-content-center">
    <div class="d-flex align-items-center search-container">
      <button class="btn ai-suggestions"
        [ngStyle]="{ 'background-color': (isToggleActive$ | async) === true ? 'white' : 'rgba(255, 255, 255, 0)' }"
        (click)="suggestionToggleClick(true)">
        {{ 'Resultados de la búsqueda' | translate }}
      </button>
      <button class="btn ai-suggestions"
        [ngStyle]="{ 'background-color': (isToggleActive$ | async) === false ? 'white' : 'rgba(255, 255, 255, 0)' }"
        (click)="suggestionToggleClick(false)">
        <span class="new-tag">{{ '¡Nuevo!' | translate }}</span>
        {{ 'Sugerencias con Inteligencia Artificial' | translate }}
      </button>
    </div>
  </div>

  <!-- Suggestions Messages -->
  <div class="basic-domain-result-text">
    {{ ((errorSearchVisible$ | async) === true ? errorSuggestionMessage : successSuggestionMessage) | translate }}
  </div>

  <!-- Display Suggestions  -->
  <ng-container *ngIf="( isToggleActive$ | async )">
    <div *ngFor="let suggestion of suggestions$ | async; let i = index; trackBy:identify">
      <ng-container
        *ngTemplateOutlet="domainSuggestionTemplate; context: { suggestion: suggestion, index: i, isAI: false }"></ng-container>
    </div>
  </ng-container>

  <!-- Display Suggestions AI -->
  <ng-container *ngIf="( isToggleActive$ | async ) === false">
    <div *ngFor="let suggestion of suggestionsAI$ | async; let i = index; trackBy:identify">
      <ng-container
        *ngTemplateOutlet="domainSuggestionTemplate; context: { suggestion: suggestion, index: i, isAI: true }"></ng-container>
    </div>
  </ng-container>

  <div class="fs-sm black my-3" *ngIf="isFeatureSuggestionsVisible">
    {{ 'Ya que hayas agregado el dominio al carrito podrás configurarlo y agregarle servicios para tener un producto más completo.' | translate }}
    <br>
    <span class="text-uppercase">{{vatMessage | translate}}</span>
  </div>

  <div class="d-block d-md-flex align-items-center justify-content-between">
    <span class="black msb text-uppercase"> {{ vatMessage | translate}} </span>

    <div class="d-flex pt-4 pt-md-0 suggestions-buttons-container"
      [ngStyle]="{'justify-content': (searchSuggestionsVisible$ | async) ? 'space-between' : 'end'}">

      <a *ngIf="(searchSuggestionsVisible$ | async) && ( isToggleActive$ | async ) && suggestionsVisible$ | async"
        class="text-nowrap btn btn-secondary" (click)="onSearchAlternativesClicked()">
        {{'Ver más resultados' | translate}}
      </a>
      <!-- add all suggestions to cart -->
      <button *ngIf="(isSuggestionsLoading$ | async) === false" id="searchDomainForm_addAllSuggests"
        class="text-nowrap btn btn-primary" [disabled]="(addAllDomainsEnabled$ | async)=== false"
        (click)="onAddAllDomainsToCartClicked()">
        {{'Agregar todos' | translate}}
      </button>
    </div>
  </div>
  <br>
  <br>
</div>

<ng-container *ngIf="isSuggestionsLoading$ | async">
  <app-loading text=" {{ searchingSuggestionsMessage | translate }}"></app-loading>
</ng-container>

<!-- Domain alternative or suggestion template -->
<ng-template #domainSuggestionTemplate let-suggestion="suggestion" let-index="index" let-isAI="isAI">

  <div *ngIf="suggestion" class="row w-100 justify-content-between align-items-center p-0 mx-0 my-3">

    <!-- Domain name -->
    <div class="domain-name-container col-12 col-lg-7 d-block d-md-inline text-start m-0 p-0">
      <span class="fs-xl black"> {{ suggestion.domainName }} </span>
      <!-- Offer icon -->
      <span *ngIf="suggestion.beforePrice" title="{{ offerMessage | translate }}" (click)="goToPromotions()"
        class="clickable fas i-offer domain d-inline-block"></span>
      <span *ngIf="suggestion?.available && suggestion?.isAvailableOffline" class="fs-xl clickable help-icon-container">
        <img loading="lazy" data-src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}"
          alt="Question mark" src="{{ '/icons/Colores/question-circle-green.svg' | blobstorage }}">
        <span class="tooltipTextSearchDomainOffline">
          <small>
            {{'Favor de contactar a Servicio al Cliente' | translate}}
          </small>
        </span>
      </span>

    </div>

    <!-- Price and add to cart -->
    <div
      class="col-12 col-lg-5 d-flex flex-column flex-lg-row justify-content-center justify-content-lg-end align-items-center p-0 m-0">

      <div class="row col-6 m-0 p-0 my-3 my-lg-0 me-0 me-lg-3 domain-price-container">
        <!-- beforePrice -->
        <div
          class="col-4 col-md-2 col-lg-6 d-flex align-items-center justify-content-start justify-content-lg-end m-0 p-0"
          [ngClass]="{ 'd-none d-lg-block': !suggestion.beforePrice }">
          <span *ngIf="suggestion.beforePrice" class="gray price searchResult-domainPriceMxn price pe-2">
            <del>
              {{ suggestion.beforePrice | currency}} <span> MXN</span>
            </del>
          </span>
        </div>
        <!-- price -->
        <div class="col-8 col-md-10 col-lg-6 d-flex align-items-center m-0 p-0 price justify-content-start">
          <span class="me-2 fs-xl black">$ {{ suggestion.price }}</span>
          <span class="searchResult-domainPriceMxn"> MXN </span>
        </div>
        <!-- Online payment -->
        <div class="col-4 col-md-2 col-lg-6 m-0 p-0" [ngClass]="{ 'd-none d-lg-block': !suggestion.beforePrice }"></div>
        <div *ngIf="domain?.onlinePayment" class="col-6 m-0 p-0 text-start">
          <div class="gray-payment-message">{{'(Pago en línea)' | translate}}</div>
        </div>
      </div>

      <div class="add-suggestion-container p-0 m-0">
        <!-- Add to cart button -->
        <button *ngIf="suggestion?.available && !suggestion?.isAvailableOffline"
          [attr.id]="'searchDomainForm_alternativeResults_' + index" (click)="onAddToCart(suggestion, isAI)"
          [disabled]="!(suggestion.isEnabled)" class="btn btn-secondary ms-md-0 text-nowrap">
          {{'Agregar al carrito' | translate}}
        </button>
        <span *ngIf="suggestion?.available && suggestion?.isAvailableOffline"
          class="gray price searchResult-domainPriceMxn price pe-2">{{ 'Disponible fuera de línea' | translate }}</span>
      </div>
    </div>

  </div>
  <hr>
</ng-template>
